<template>
  <div>
    <FloatLabel :class="`${selectDisabled ? 'float-label' : ''}`" :label="label" :optional="optional" :ask="ask" :tooltip="tooltip">
      <select
        class="shadow-none form-control select-custom"
        v-model="val"
        v-on:change="handleChange"
        :disabled="selectDisabled || false"
        :class="[`${error ? 'invalid' : ''} ${selectDisabled ? 'disabled' : ''} ${this.selectValue !== '' ? '' : 'gray'}`]"
        v-if="isObjectOptions"
      >
      <option value="" :disabled="label !== 'Model year'">{{ this.options.length === 0 ? 'N/A' : 'Select one'}}</option>
        <option
          v-for="(e, idx) in options" :key="idx" :value="e.value"
        >{{ e.key }}</option>
      </select>
      <select
        class="shadow-none form-control select-custom"
        v-model="val"
        v-on:change="handleChange"
        :disabled="selectDisabled || false"
        :class="[`${error ? 'invalid' : ''} ${selectDisabled ? 'disabled' : ''} ${this.selectValue !== '' ? '' : 'gray'}`]"
        v-else
      >
        <option value="" :disabled="label">{{ this.options.length === 0 ? 'N/A' : 'Select one'}}</option>
        <option v-for="(e, idx) in options" :key="idx" :value="label === 'Colour' ? e.toUpperCase() : e">{{ e }}</option>
      </select>
    </FloatLabel>
    <div class="fs-12 mb-2 mt-n3 pr-2 err-text" :class="isLeftAlign ? 'text-left' : 'text-right'">{{ error ? helperText : '' }}</div>
  </div>
</template>
<script>
export default {
  components: { 
    FloatLabel: () => import('./FloatLabel.vue')
  },
  name: 'CustomSelect',
  inheritAttrs: false,
  model: {
    prop: 'selectValue',
    event: 'change'
  },
  props: {
    selectValue: {
      type: [Number, String],
      default: () => ""
    },
    options: {
      type: Array,
      default: () => [],
    },
    label: String,
    name: {
      type: String,
      default: () => ""
    },
    isNa: {
      type: Boolean,
      default: false,
    },
    ask: {
      type: Boolean,
      default: () => false
    },
    tooltip: {
      type: String,
      default: () => ""
    },
    error: Boolean,
    helperText: String,
    isFocus: Boolean,
    optional: Boolean,
    selectDisabled: Boolean,
    isLeftAlign: Boolean,
  },
  computed: {
    val: {
      get: function() {
        return this.selectValue;
      },
      set: function() {},
    },
    isNaCondition: function() {
      return this.options.length === 0 && this.isNa
    },
    isObjectOptions: function() {
      const bypassList = ['Variant', 'Service history details'];
      return bypassList.includes(this.label);
    }
  },
  methods: {
    handleChange: function (e) {
      this.$emit('change', e.target.value)
      this.$emit('setNextInput')
      e.target.blur()
    },
    convertShiftValue: function (e) {
      if(e === 'AT') {
        return 'Automatic'
      } else {
        return 'Manual'
      }
    },
    // optionParser: function (e) {
    //   return e !== 'Not sure' ? 'MY' + e.toString().substring(2) : e;
    // },
  }
}
</script>
<style lang="scss">
select.form-control.invalid, input.form-control.invalid {
  border-color: red !important ;
}
select.form-control.disabled {
  border: none;
}
.float-label > span{
  z-index: 0;
  background-color: transparent;
  &::after {
    background: none;
  }
}
.err-text {
  color: red;
  font-style: italic;
  min-height: 16px;
}
.border-lable-flt {
  margin-bottom: 0;
}
.non-foucus {
  border-color: #ced4da !important;
}
.next-select-input {
  color: #383C3F;
  background-color: #fff;
  border-color: #00BB65 !important;
  outline: 0;
}
.select-custom {
  &.gray {
    color: #989898 !important;
  }
  
  option {
    color: #383c3f !important;

    &:first-child {
      color: #989898 !important;
    }
  }
}
</style>